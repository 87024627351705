import {FaqCategoryTypeDTO, FaqCategoryTypeEntity, FaqTypeDTO, FaqTypeEntity} from './faq.type'
import {useParseData} from '../../../utils/parse.utils'
import {useGenerateUrl} from '../../../utils/url.utils'
import {Logger} from '../../../utils/logger.utils'

export const useFaqUtils = (settings: any) => {
  const {generateDefaultFieldDirectus, getTransItemMulti} = useParseData()
  const {generateLangSwitch, generateUrlByNameRoute} = useGenerateUrl(settings)

  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);

  const generateFaqDto = (item: FaqTypeEntity, languageCode: string): FaqTypeDTO | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        show: item.show ?? false,
        title: getTransItemMulti(item, languageCode, 'title'),
        slug: getTransItemMulti(item, languageCode, 'slug'),
        intro: getTransItemMulti(item, languageCode, 'intro'),
        content: getTransItemMulti(item, languageCode, 'content'),
        seo_detail: getTransItemMulti(item, languageCode, 'seo_detail'),
        url: generateUrlByNameRoute('faq_detail', languageCode, {
          id: item.id,
          slug: getTransItemMulti(item, languageCode, 'slug')
        })
      }

      if (item.category) {
        output['category'] =
          typeof item.category === 'object'
            ? generateFaqCategoryDto(item.category, languageCode)
            : item.category
      }
      output['lang_switch'] = generateLangSwitch('faq_detail', languageCode, item)
      output.translations = {
        title: getTransItemMulti(item, languageCode, 'title'),
        slug: getTransItemMulti(item, languageCode, 'slug'),
        intro: getTransItemMulti(item, languageCode, 'intro'),
      }
      // delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateFaqDto : ', e)
      return {}
    }
  }

  const generateFaqCategoryDto = (item: FaqCategoryTypeEntity, languageCode: string): FaqCategoryTypeDTO | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        show: item.show ?? false,
        title: getTransItemMulti(item, languageCode, 'title'),
        slug: getTransItemMulti(item, languageCode, 'slug'),
        intro: getTransItemMulti(item, languageCode, 'intro'),
        icon: item.icon,
        seo_detail: getTransItemMulti(item, languageCode, 'seo_detail'),
        url: generateUrlByNameRoute('faq_category', languageCode, {
          id: item.id,
          slug: getTransItemMulti(item, languageCode, 'slug')
        }),
        translations: {
          title: getTransItemMulti(item, languageCode, 'title'),
          slug: getTransItemMulti(item, languageCode, 'slug'),
          intro: getTransItemMulti(item, languageCode, 'intro'),
        }
      }

      if (item.faqs && Array.isArray(item.faqs) && item.faqs.length > 0) {
        output['faqs'] = item.faqs.map((ite) =>
          typeof ite === 'object' ? generateFaqDto(ite, languageCode) : ite
        )
      }

      output['lang_switch'] = generateLangSwitch('faq_category', languageCode, item)

      // delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateFaqCategoryDto: ', e)
      return {}
    }
  }

  return {
    generateLangSwitch,
    generateFaqCategoryDto,
    generateFaqDto,
    logger
  }
}
